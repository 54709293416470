import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Image from "gatsby-image";
import Img from "gatsby-image";

export const AmazingShopView = () => {

    return (
        // <React.Fragment>
        <div>
            <h5>Available Features</h5>
            <h5>Coming soon</h5>
        </div>

        // </React.Fragment>
    )
}